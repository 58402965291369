import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getPartnerData, partnerUpdate } from "./service";
import { getServices } from "../RcServices/service";
import { AuthContext } from "../../AppContext";


const pageLimit = [10, 25, 50, 100].map(
    item => ({ label: item, value: item })
);
const RcPartner = () => {
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [loading, setIsLoading] = useState(true)
    const [allPartnerData, setAllPartnerData] = useState([])
    const [searchPartner, setSearchPartner] = useState("")
    const [allServices, setAllServices] = useState([{ label: "All", value: "" }]);
    const [selectedServices, setSelectedServices] = useState("");
    const { userPermission,token } = useContext(AuthContext)

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const fetchPartnerData = async () => {
        setIsLoading(true)
        try {
            const formData = new FormData()
            formData?.append("page", current)
            formData?.append("limit", size)
            formData?.append("name", searchPartner?.trim())
            formData?.append("service_id", selectedServices)
            const response = await getPartnerData(formData,token)
            if (response?.status) {
                setAllPartnerData(response)
            } else {
                setAllPartnerData([])
                toast?.error("Data not Found")
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }
    /**Status Change */
    const Togglechange = async (id, status) => {

        const Form = new FormData();
        Form.append("id", id);
        Form.append("status", status ? 1 : 0);
        try {

            const result = await partnerUpdate(Form,token);
            if (result) {
                toast.success("Status Update successfully");
                fetchPartnerData();
            }
        } catch (error) {
            console.log('error', error)
        }
    };

    const handleSearch = () => {
        fetchPartnerData();
        setCurrent(1);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch()
        }
    };

    const fetchServices = async () => {
        // setIsLoading(true);
        try {
            const response = await getServices({},token);
            console.log('response', response)
            if (response?.status) {
                const transformData = (data) => {
                    return data?.map(item => ({
                        value: item?.id,
                        label: item?.name
                    }));
                };

                setAllServices([...allServices, ...transformData(response?.data)]);
            } else {
                setAllServices([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            // setIsLoading(false);
        }
    };

    /**delete service*/
    let count = 10
    let swalCountdownInterval
    // const handleDelete = async (id) => {
    //     const swalWithBootstrapButtons = Swal.mixin({
    //         customClass: {
    //             confirmButton: "btn btn-danger btn-lg counter",
    //             cancelButton: "btn btn-primary btn-lg me-3",
    //         },
    //         buttonsStyling: false,
    //     });

    //     swalWithBootstrapButtons
    //         .fire({
    //             title: "Are you sure you want to delete?",
    //             text: "You won't be able to revert this!",
    //             imageUrl: '../../icon/alert.svg',
    //             imageWidth: 80,
    //             imageHeight: 80,
    //             confirmButtonText: "OK (10)",
    //             cancelButtonText: "Cancel",
    //             showCancelButton: true,
    //             reverseButtons: true,
    //             didOpen: () => {
    //                 $(".swal2-confirm").attr('disabled', true);
    //                 swalCountdownInterval = setInterval(function () {
    //                     count--
    //                     if (count < 1) {
    //                         $(".counter").text(`OK`)
    //                         $(".swal2-confirm").attr('disabled', false);
    //                         clearInterval(swalCountdownInterval)
    //                     } else {
    //                         $(".counter").text(`OK (${count})`)
    //                     }
    //                 }, 1000);
    //             }
    //         })
    //         .then(async (result) => {
    //             try {

    //                 if (result.isConfirmed) {
    //                     const formData = new FormData()
    //                     formData?.append("id", id)
    //                     const deletedata = await deleteService(formData)
    //                     fetchPartnerData()
    //                 } else {
    //                     count = 10
    //                     clearInterval(swalCountdownInterval)
    //                 }
    //             } catch (error) {
    //                 console.log('error', error)
    //             }
    //         });
    // };

    useEffect(() => {
        fetchPartnerData()
    }, [current, size, selectedServices])
    useEffect(() => {
        fetchServices()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">RC Partner Report <span></span></h3>
                <div className="page-heading-right">
                    <div className="inputgroup my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search Partner"
                            className="wv-325 ms-3"
                            onChange={(e) => { e.target.value == "" && handleSearch(); setSearchPartner(e.target.value) }}
                            onKeyPress={handleKeyPress}
                        />
                        <i className="bx bx-search" onClick={handleSearch} />
                    </div>
                    <SelectPicker
                        cleanable={false}
                        data={allServices}
                        searchable={false}
                        value={selectedServices}
                        onChange={(e) => { setSelectedServices(e); setCurrent(1) }}
                        className="wv-150 my-1 ms-3"
                        placeholder="Select Date"
                    />


                    <SelectPicker
                        cleanable={false}
                        data={pageLimit}
                        searchable={false}
                        onChange={(e) => { setSize(e); setCurrent(1) }}
                        value={size}
                        className="wv-100 my-1 ms-3"
                    />
                    {userPermission["RC-Partner"]?.add == 1 &&
                        <Link to="/rc-partner/add" className="btn btn-primary my-1 ms-3">Add New</Link>
                    }
                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="45%" className="text-center">Partner Name</th>
                                    <th width="20%" className="text-center">Status</th>
                                    <th width="10%" className="text-center">Show Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allPartnerData?.data?.length > 0 ? allPartnerData?.data?.map((item, index) => {
                                    return (<tr key={index}>
                                        <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                        <td className="text-center">{item?.name}</td>
                                        <td className="text-center"> <Form.Check
                                            type="switch"
                                            onChange={(e) => {
                                                Togglechange(item?.id, e.target.checked);
                                            }} name="status"
                                            disabled={userPermission["RC-Partner"]?.edit != 1}
                                            checked={item?.status === 1 ? true : false}
                                        /></td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                {userPermission["RC-Exchange-Report"]?.view == 1 &&
                                                    <><Link to={`/rc-partner/partner-rc-count`}
                                                        state={{ id: item.id, service_id: item?.service_id,partner:item?.name }}
                                                        className="btn btn-icon btn-transparent btn-danger">
                                                        <i className='bx bx-receipt'></i>
                                                    </Link>
                                                        <span className='border-start py-2 mx-2'></span>
                                                        </>}
                                                <Link to={`/rc-partner/view/${item?.id}`} className="btn btn-icon btn-transparent btn-primary">
                                                    <i className='bx bx-show'></i>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>)
                                }) :
                                    <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found RC Partner Report</p>
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </Table>
                        {allPartnerData?.totalcount > size && <div className="pagination-custom">
                            <Pagination
                                showTitle={false}
                                className="pagination-data"
                                onChange={paginationData}
                                total={allPartnerData?.totalcount}
                                current={current}
                                pageSize={size}
                                showSizeChanger={false}
                            />
                        </div>}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default RcPartner